.table-sort-buttons {
  display: flex;
  flex-direction: column;
}

.main-table-section {
  height: 100%;
  max-height: 440px;
  max-width: 100%;
  overflow-x: auto;
  position: relative;
  clear: both;
}

.main-table-section::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.main-table-section::-webkit-scrollbar-track {
  background-color: white;
  padding: 10px 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.main-table-section::-webkit-scrollbar-thumb {
  background-color: #e4e4e4;
  border-radius: 6px;
}

.main-table-section::-webkit-scrollbar-thumb:hover {
  background-color: #e4e4e4;
}

.bootstrap-table thead tr th {
  padding: 8px 2px !important;
  box-sizing: border-box;
  border-bottom: 1px solid #d8d8d8 !important;
  border-left: 1px solid #d8d8d8 !important;
  border-right: 1px solid #d8d8d8 !important;
}

.bootstrap-table tbody tr td {
  color: #181d27;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12.5px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border-right: 1px solid #d8d8d8 !important;
}

.bootstrap-table tbody tr td .seller-name {
  width: 100%;
  text-align: left;
  max-width: 110px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.bootstrap-table tbody tr td .seller-name a {
  text-decoration: none;
  display: block;
  overflow: hidden;
  color: #181d27 !important;
  text-overflow: ellipsis;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.bootstrap-table tbody tr td .seller-name a:hover {
  color: #155eef !important;
}

.bootstrap-table thead th .th-inner {
  padding: 0 1.15rem 0 0px !important;
  vertical-align: bottom;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #717680;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.bootstrap-table thead th .both {
  background-image: url("../../Assests/both-img.svg");
  cursor: pointer;
  background-position: right;
  background-repeat: no-repeat;
  background-size: 15px;
}

.bootstrap-table thead th .sort-up {
  background-image: url("../../Assests/up-icon.svg");
  cursor: pointer;
  background-position: right;
  background-repeat: no-repeat;
  background-size: 11px;
}

.bootstrap-table thead th .sort-down {
  background-image: url("../../Assests/down-icon.svg");
  cursor: pointer;
  background-position: right;
  background-repeat: no-repeat;
  background-size: 11px;
}

.bootstrap-table > tbody > tr > .amazon-seller {
  font-weight: 600 !important;
  font-size: 10.5px !important;
}

.table-heading {
  text-align: center;
  padding-right: 18px !important;
  font-size: 12px;
  cursor: pointer;
}

.table-data {
  padding: 20px;
  white-space: nowrap;
  text-align: center !important;
  font-size: 11px !important;
}
.bootstrap-table tbody tr .table-data {
  font-size: 11px !important;
}
.table-data-variation {
  padding: 20px;
  white-space: wrap;
  text-align: left !important;
  font-size: 12px !important;
}

.sidebar::-webkit-scrollbar {
  width: 4px;
  height: 50%;
}

.sidebar::-webkit-scrollbar-track {
  background-color: transparent;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #f86900;
  border-radius: 5px;
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background-color: #f86900;
}

.main-table-section thead th {
  position: sticky;
  top: -0.5px;
  z-index: 1;
  background-color: #343a40;
  color: white;
  text-align: center;
  border: 1px solid #343a40;
}

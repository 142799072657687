@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');


body {
    font-family: "Outfit", sans-serif !important;
}

.remarkable-component {
    background-image: url("../../Assests/bg-remarkable.svg");
    min-height: 100vh;
    height: 100%;
    padding: 44px 44px 0px 44px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 70%;
}

.content-logo {
    padding-top: 100px;
    max-width: 444px;
    width: 100%;
}

.content-logo h1 {
    color: #FFF;
    font-family: Outfit, sans-serif !important;
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: 58px;
}

.content-logo p {
    color: rgba(255, 255, 255, 0.75);
    font-family: Outfit, sans-serif !important;
    font-size: 16px;
    margin-top: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.clip {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.clip img {
    max-width: 100%;
    height: auto;
    margin: 0px 14px;
    transition: transform 0.3s, opacity 0.3s;
}

.login-slider-padding {
    padding-top: 80px !important;
    padding-bottom: 40px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: white !important;
}

.slick-dots li button:before {
    font-family: "Slick", sans-serif;
    font-size: 12px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: "•";
    text-align: center;
    opacity: 0.25;
    color: #f2f2f2 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fpBGKh {
    overflow-y: hidden !important;
    overflow-x: auto !important;
}

/* Hover Image settings */
  .MuiTooltip-popperInteractive:has(.enlarged-image) {
    right: 0px !important;
    bottom: 10px;
    min-height: 40vh;
}
.css-3v9qdb-MuiTooltip-tooltip:has(.enlarged-image) { 
    max-width: 155px !important;
    margin: 30vh 40px 0px auto !important;
}

@media (max-width: 768px) {
    .container-fluid .remarkable-component {
        display: none !important;
    }

    .login-form-section {
        padding: 30px 20px !important;
    }

    .login-form-content h1 {
        font-size: 26px !important;
    }

    .login-form-content p {
        font-size: 14px !important;
    }

    .login-form-content {
        padding-bottom: 24px !important;
    }

    .form-input-text {
        font-size: 16px !important;
    }

    .label-text,
    .login-btn,
    .create-account {
        font-size: 14px !important;
    }
}
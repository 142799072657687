.verification-container {
    text-align: center;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    max-width: 600px;
    width: 100%;
}
.verification-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 20px auto;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.verification-message {
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 50px;
    font-family: Inter, Arial, sans-serif !important;
    font-weight: 500;
    color: black;
}
.verification-message-success{
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 20px;
    font-family: Inter, Arial, sans-serif !important;
    font-weight: 500;
    color: black;
    line-height: 25px;
}
.verification-message-error{
    font-size: 16px !important;
    margin-bottom: 20px;
    margin-top: 20px;
    font-family: Inter, Arial, sans-serif !important;
    font-weight: 500;
    color: black;
    line-height: 25px;
    cursor: pointer;
    text-decoration: underline !important;
}
.verification-message-success-h2{
    margin-bottom: 20px;
    margin-top: 20px;
    font-family: Inter, Arial, sans-serif !important;
    font-size: 28px;
    font-weight: 500;
    color: black;
    
}
.scan-table {
  cursor: pointer;
}

.enlarged-image {
  width: 145px;
  height: 150px;
}

.hyperlink-style {
  text-decoration: none;
  color: #4b1b97;
  cursor: pointer;
  font-weight: "bold";
}

.graph-image {
  width: 100%;
  height: 100%;
}

.css-19qwjc1 .MuiDataGrid-container--top [role="row"],
.css-19qwjc1 .MuiDataGrid-container--bottom [role="row"] {
  background: #e2ecff !important;
}

.asin {
  width: 10px !important;
}

.css-t89xny-MuiDataGrid-columnHeaderTitle {
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 20px !important;
  white-space: normal !important;
  font-size: 14px !important;
  font-weight: var(--unstable_DataGrid-headWeight);
}

.even-row {
  background-color: #f3f3f3 !important;
}

.tr.mat-row {
  height: 48px;
}

.star-colour {
  color: #00c853 !important;
}

.odd-row {
  background-color: white !important;
}

.modal-button {
  height: 40px;
  font-family: "Albert", sans-serif !important;
  color: #fff;
  background: #00c853 !important;
  box-shadow: none !important;
  border: none !important;
}

.modal-button :hover {
  background: #00c853 !important;
}

:where(.css-dev-only-do-not-override-6j9yrn).ant-btn-primary {
  color: #fff;
  background: #00c853 !important;
  box-shadow: none !important;
  border: none !important;
}

:where(.css-dev-only-do-not-override-6j9yrn).ant-btn-primary:hover {
  background: #00c853 !important;
}

.scan-header {
  font-weight: bold;
}

:where(.css-6j9yrn).ant-btn-primary {
  color: #fff;
  background: #19c853;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}

.param-value {
  text-align: center !important;
}

:where(.css-6j9yrn).ant-btn-primary:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  color: #fff;
  background: #19c853;
}

.MuiDataGrid-cell,
.centered-cell {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.MuiDataGrid-row:hover {
  background-color: #f3f3f3 !important;
}

.MuiDataGrid-cell button svg {
  color: black;
  font-size: 16px;
}

.css-ptkaw2-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.css-ptkaw2-MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.MuiDataGrid-columnHeader {
  font-size: 12px;
  font-weight: 500;
  color: #ffffff !important;
}
.new-scans-table .MuiDataGrid-columnHeader {
  font-size: 12px;
  font-weight: 500;
  color: #808080 !important;
}
.css-i4bv87-MuiSvgIcon-root {
  font-size: 20px !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
  color: #00c853 !important;
}

.share-options {
  color: #808080 !important;
  font-size: 12px;
  margin-left: 10px;
  transform: scale(1.1);
}

.share-options:hover,
.share-options:active {
  transform: scale(1.2);
}

.ant-popconfirm-buttons button,
.ant-popconfirm-buttons .ant-btn {
  width: unset !important;
}

:where(.css-ni1kz0).ant-modal .ant-modal-footer,
.basic-modal .ant-modal-footer {
  display: flex !important;
  justify-content: space-between !important;
}

.ant-popover-content {
  width: 246px;
}

.enter-popover .ant-modal-body .css-ya9l6k {
  width: 100%;
  margin: 0px !important;
}

.enter-popover .css-ya9l6k {
  margin: 0px I !important;
}

.custom-tooltip {
  max-width: 700px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.custom-tooltip-item {
  max-width: 700px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px !important;
  font-size: 12px !important;
  background-color: #000 !important;
}
.css-t4440l .MuiDataGrid-container--top [role="row"],
.css-t4440l .MuiDataGrid-container--bottom [role="row"] {
  background: #4b1b97 !important;
}

.fba-radio input[type="radio"] {
  accent-color: #4b1b97;
  cursor: pointer;
}

.fba-radio span {
  font-weight: bold;
  font-size: 14px;
}

.scan-setting-text {
  font-size: 19px;
  font-weight: 500;
  font-family: Inter, Arial, sans-serif !important;
  color: #000;
}
.css-1ro85z9-MuiTypography-root {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1.2rem !important;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgb(0 0 0) !important;
}
.image-tooltip {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.image-tooltip-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image-tooltip-content {
  position: relative;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 9999 !important;
  /* */
}
.image-tooltip-content-keepa {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 9999 !important;
}
.image-tooltip-content img {
  max-width: 400px;
  max-height: 450px;
  border-radius: 4px;
}
.image-tooltip-content-keepa img {
  width: 925px;
  height: 366px;
  border-radius: 4px;
}

.assign-icon {
  font-size: 20px !important;
  color: #808080 !important;
}
.calculator-icon {
  color: #808080 !important;
  margin-bottom: 3px !important;
}
.info-icon {
  color: #808080 !important;
}

.copy-icon-container {
  visibility: hidden;
}

div:hover .copy-icon-container {
  visibility: visible;
}

.new-scan-box .css-imrjgg-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
  color: #4b1b97 !important;
}
.new-scan-add-btn {
  background-color: #4b1b97;
  color: white;
  border: transparent;
  transition: all 0.3s ease; 
}

.btn-refresh-scans {
  display: flex;
  width: 29px;
  height: 27px;
  padding: 1px !important;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px !important;
  border: none !important;
}
.badge-cell{
 height: 100%;
 display: flex;
 align-items: center;
}

.btn-refresh-scans svg {
  height: 17px;
  width: 17px;
}

.css-1rxsvxt {
  font-size: 16px !important;
}

.new-scans-table .css-1rxsvxt {
  color: #181D27 !important;
}

.css-ni1kz0 {
  z-index: unset !important;
  width: unset !important;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-btn-variant-solid,
:where(.css-ni1kz0).ant-btn-variant-solid {
  background-color: #4b1b97;
  font-family: Outfit, sans-serif !important;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-btn-variant-outlined {
  font-family: Outfit, sans-serif !important;
}

:where(.css-dev-only-do-not-override-ni1kz0).ant-btn-variant-solid:not(
    :disabled
  ):not(.ant-btn-disabled):hover,
:where(.css-ni1kz0).ant-btn-variant-solid:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  background: #4b1b97;
}

:where(.css-ni1kz0).ant-btn-variant-outlined:not(:disabled):not(
    .ant-btn-disabled
  ):hover,
:where(.css-dev-only-do-not-override-ni1kz0).ant-btn-variant-outlined:not(
    :disabled
  ):not(.ant-btn-disabled):hover {
  border-color: #4b1b97 !important;
  color: #4b1b97 !important;
}

.css-imrjgg-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
  color: #808080 !important;
}
.enable-power-up-data .css-imrjgg-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
    color: #1976d2 !important;
}
.enable-power-up-data .css-1umw9bq-MuiSvgIcon-root{
 width: 1.2em;
 height: 1.2em;
}
.enable-power-up-data .css-imrjgg-MuiButtonBase-root-MuiCheckbox-root {
    padding: 9px 7px 9px 1px;
}
.css-19opgx6-MuiFormControlLabel-root {
    margin-right: 13px;
}
.enable-power-up-data .css-19opgx6-MuiFormControlLabel-root{
    margin-right: 5px;
}
.css-aymtem-MuiDataGrid-virtualScrollerContent {
  height: auto !important;
}
.get-pro-span{
    font-size: 14px;
    font-weight: 700;
    color: black;
    padding-top: 6px;
    text-decoration: underline;
}
.css-2hy7sk-MuiFormLabel-root-MuiInputLabel-root,
.css-j218zi-MuiInputBase-root-MuiInput-root-MuiSelect-root {
  font-family: Outfit, sans-serif !important;
}

.css-20a7a0 {
  height: unset !important;
}

.css-j0riat-MuiSelect-nativeInput {
  font-family: Outfit, sans-serif !important;
}

.btn-download-scans {
  display: flex;
  width: 29px;
  height: 27px;
  padding: 1px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px !important;
  border: none !important;
}

.btn-download-scans svg {
  width: 17px;
  height: 17px;
}

.MuiDataGrid-cell button svg {
  color: #4b1b97 !important;
  font-size: 14px !important;
}
.MuiDataGrid-cell button:has(.info-icon) svg {
  color: #808080 !important;
  font-size: 20px !important;
}

.table-pagination
  .css-ptkaw2-MuiDataGrid-root
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
  > .MuiDataGrid-cell {
  min-height: 22px !important;
  max-height: 22px !important;
  height: 22px !important;
}

.custom-grid-table .MuiDataGrid-row:nth-child(odd) {
  display: flex;
  align-items: center;
}

.custom-grid-table .MuiDataGrid-row:nth-child(even) {
  display: flex;
  align-items: center;
}

.table-pagination .css-1kwdphh-MuiDataGrid-virtualScrollerContent {
  height: 470px !important;
}

.css-aop0yh .MuiDataGrid-columnHeaderTitleContainer {
  color: white !important;
}

.custom-grid-table .css-t89xny-MuiDataGrid-columnHeaderTitle {
  color: white !important;
}

.css-aop0yh .MuiDataGrid-cell:focus {
  outline: none !important;
}

.scans-table-page {
  position: relative !important;
}

.pagination-dialog {
  background-color: #FFFFFF;
  min-height: 50px;
  position: relative !important;
  padding: 0px 0px !important;
  width: 100% !important;
  right: 0px;
  top: -24px !important;
  border-radius: 0px 0px 4px 4px !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
  outline: none !important;
}

.css-11dqcl8-MuiDataGrid-virtualScrollerRenderZone {
  display: flex;
  gap: 0px;
  margin-top: 0px;
}

.css-dwkzlu-MuiDataGrid-root
  .MuiDataGrid-virtualScrollerContent
  .MuiDataGrid-row {
  max-height: 40px !important;
  min-height: 40px !important;
}

.css-dwkzlu-MuiDataGrid-root .MuiDataGrid-cell {
  max-height: 40px !important;
  min-height: 40px !important;
}

.css-dwkzlu-MuiDataGrid-root .MuiDataGrid-cell--textLeft {
  display: flex;
  align-items: center;
}


.css-dwkzlu-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
  outline-offset: none !important;
}

.css-dwkzlu-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.css-dwkzlu-MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
  outline-offset: none !important;
}

.css-dwkzlu-MuiDataGrid-root .MuiDataGrid-container--top [role="row"] {
  background-color: #4b1b97 !important;
  color: white !important;
}

.css-dwkzlu-MuiDataGrid-root
  .MuiDataGrid-row--borderBottom
  .MuiDataGrid-columnHeader {
  color: white !important;
}

.css-dwkzlu-MuiDataGrid-root
  .MuiDataGrid-columnHeaderTitleContainerContent:first-child {
  margin: 0 0 !important;
  font-size: 14px !important;
}

.css-1bgckiu-MuiDataGrid-main {
  max-height: 520px;
  min-height: 520px;
}

.css-dwkzlu-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainerContent {
  margin: 0 auto !important;
}

.css-dwkzlu-MuiDataGrid-root {
  max-height: 520px;
  min-height: 520px;
}

.new-scans-table .css-1bgckiu-MuiDataGrid-main {
  max-height: 520px;
  min-height: 570px;
}

.new-scans-table .MuiDataGrid-virtualScrollerContent,
.new-scans-table .css-1xdhyk6 {
  height: 480px !important;
}

.padding-icon {
  padding-top: 11px !important;
}
.indicator-icon {
  font-size: 20px !important;
  color: #757575 !important;
  margin-right: 7px !important;
}


.completed-badge{
  background-color: #289F341A;
  color: #289F34;
  height: 32px;
  width: 106px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin: auto !important;
}
.new-scans-table  .css-5wdp45-MuiDataGrid-root{
  border: none !important;
}
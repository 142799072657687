.app-container:has(.scans-section){
    background-color: #eff1f7;
}

.scans-card{
    background-color: #FFFFFF;
    border-radius: 6px;
    color: #181D27;
    box-shadow: 0px 4px 20px 0px #9D999926;
    height: 150px;
    flex-shrink: 0;
    padding: 10px;
   

}

.scans-feature-card {
    background-color: #155EEF0D;
    border-radius: 6px;
    color: #757575;
    box-shadow: 0px 4px 20px 0px #9D999926;
    height: 150px;
    flex-shrink: 0;
    padding: 10px;
    border: 2px dotted #C1BDBD;
}

.scans-card .title {
    font-size: 25px;
    font-weight: 700;
}
.scans-card .sub-title, .scans-feature-card .sub-title {
    font-size: 16px;
    color: #757575;
    font-weight: 400;
    padding-top: 8px;
}

.scans-feature-card p {
    padding-top: 8px;
}

.format-text{
    color: #B4B4B4;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
}
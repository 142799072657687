 .accordion-item-help {
     padding: 10px 0;
 }

 .chevron-icon {
     transition: transform 0.3s ease;
 }

 .chevron-icon.rotate {
     transform: rotate(180deg);
 }

 .filter-box {
     border-radius: 12px;
     border: 0.5px solid #B0B0B0;
     background: rgba(255, 255, 255, 0.00);
     width: 46px;
     height: 46px;
     padding: 10px;
     color: #ADADAD;
     font-size: 24px;
 }

 .title-heading {
     color: #181D27;
     font-family: Inter, Arial, sans-serif !important;
     font-size: 32px;
     font-style: normal;
     font-weight: 600;
     line-height: normal;
 }
 .title-heading-span {
    color: #181D27;
    font-family: Inter, Arial, sans-serif !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
 .accordion-title-help {
     background-color: #4B1B97;
     color: white;
     border-radius: 6px 6px 0px 0px;
     height: 50px;
     flex-shrink: 0;
     padding: 20px;
     cursor: pointer;
 }

 .accordion-item-help h2 {
     font-size: 22px;
     font-style: normal;
     font-weight: 500;
     line-height: normal;
 }

 .accordion-content-help {
     border-radius: 6px;
     background: var(--common-white-main, #FFF);
     box-shadow: 0px 10px 22px 0px rgba(198, 198, 198, 0.30);
     min-height: 480px;
     padding: 20px;
     position: relative;
     transition: all 0.3s ease;
 }



 .accordion-content-help ul {
     padding: 0;
 }

 .accordion-content-help li {
     color: #484848;
     font-size: 16px;
     font-weight: 500;
     list-style: disc;

 }



 .accordion-Card-help {
     min-height: 200px;
     flex-shrink: 0;
     border-radius: 6px;
     border: 1px solid var(--grey-300, #E0E0E0);
     padding: 10px 50px;
     display: flex;
     flex-direction: column;
     justify-content: center;
 }

 .accordion-Card-help ul li {
     max-width: 400px !important;
 }

 .button-group {
     display: flex;
     gap: 2px;
 }

 @media screen and (max-width: 750px) {
     .accordion-content-help {
         padding: 10px !important;
     }

     .accordion-Card-help {
         padding: 10px 20px !important;
         min-height: 120px !important;
     }

     .accordion-item-help h2 {
         font-size: 20px;
     }

     .accordion-title-help {
         height: 38px;
         padding: 4px 20px;
     }
 }
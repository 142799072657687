.panels-item {
  margin: 10px 0px;
}


.panels-title {
  font-family: Outfit, sans-serif !important;
  background-color: white;
  color: #181d27;
  border-radius: 6px 6px 0px 0px;
  height: 50px;
  flex-shrink: 0;
  padding: 10px 10px 10px 20px;
  border-bottom: 1px solid #ebebeb;
  box-shadow: 0px 4px 20px 0px #9d999926;
  cursor: pointer;
}

.panels-item h2 {
  font-family: Outfit, sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #181d27 !important;
}

.panels-content {
  border-radius: 0px 0px 6px 6px;
  background: var(--common-white-main, #fff);
  box-shadow: 0px 10px 22px 0px rgba(198, 198, 198, 0.3);
  flex-shrink: 0;
  padding: 2px;
  position: relative;
  transition: all 0.3s ease;
}

.panels-content p {
  color: #484848;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
}

.form-group-panel {
  display: flex;
  flex-direction: column;
}

.percentage-btn {
  background-color: #4b1b97;
  border-radius: 3px;
  color: white;
  width: 30px;
  height: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 0px;
}

.dollar-btn {
  background-color: #4b1b97;
  border-radius: 3px;
  color: white;
  width: 30px;
  height: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 0px;
}

.form-group-panel label {
  color: #757575;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 2px !important;
}

.panels-container .form-group-panel label {
  margin-bottom: 4px !important;
}
.app-container:has(.panels-section) {
  background-color: #F1F3FA;
}

.form-group-panel input {
  border-radius: 4px;
  border: 1px solid #b4b4b4;
  background: #fff;
  padding: 4px 15px !important;
  font-size: 14px;
  font-weight: 500;
}
.marketplace-panel{
  min-height: 235px;
}
.panels-content .form-group-panel input:focus{
  outline: none !important;
}
.panels-content .form-group-panel input {
  border-radius: 4px;
  border: 1px solid #E8E8E8;
  background: #fff;
  padding: 9px 15px !important;
  font-size: 14px;
  font-weight: 500;
}

.sas-panels-form .form-group-panel input,
.sas-panels-form .form-group-panel .draggale-para {
  background-color: #FFFFFF;
  padding: 12px 15px !important;
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  margin: 0 !important;
  color: #000;
  box-shadow: 0px 2px 10px 0px #9d999940;
}

.form-group-panel .scan-month {
  border-radius: 4px;
  border: 1px solid #b4b4b4;
  background: #fff;
  padding: 5.7px 15px !important;
  font-size: 14px;
  font-weight: 500;
}

.form-btns-panels {
  position: absolute;
  top: 22px;
  right: 20px;
}

.panels-container .form-btns-panels {
  position: absolute;
  top: 28px;
  right: 10px;
}

.form-btns-panels button {
  height: 32px !important;
  border: none !important;
  color: #155eef !important;
}

.custom-panels-select {
  cursor: pointer;
  background-image: url(/public/images/chevron-icon.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  background-size: 12px;
  border-radius: 4px !important;
  padding: 4px 15px !important;
  border: 1px solid #b4b4b4 !important;
  font-size: 14px !important;
}

.dollar-input input {
  padding-left: 40px !important;
}

.switch-panels {
  position: absolute;
  top: 15px;
  right: 0px;
}

.panels-content .MuiSwitch-switchBase.Mui-checked {
  color: #155eef !important;
}

.panels-content .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: #155eef !important;
}

.storage-btn {
  display: flex;
  width: 78px;
  height: 30px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #4b1b97;
  color: white;
  position: absolute;
  top: 20px;
  right: 0px;
  font-size: 10px;
}

.sas-drags-panels .switch-panels {
  top: 0px;
  right: -8px !important;
}

.sas-panels-form {
  overflow: hidden !important;
}

.star-group {
  display: flex !important;
  gap: 6px;
  position: absolute;
  bottom: 2px;
  left: 12px;
}

.star-btn {
  background-color: transparent;
  border-color: #fbbc31;
  color: #6c6c6c;
  display: flex;
  width: 78px;
  height: 27px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #fbbc31;
  flex-shrink: 0;
}

.star-box {
  border-radius: 4px;
  border: 1px solid #fbbc31;
  display: flex;
  width: 28px;
  height: 27px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  font-size: 30px !important;
  color: #fbbc31;
}

.move-input-icon {
  top: 6px;
}

.marketplace-image {
  width: 180px;
  height: 110px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.country-name-p {
  font-size: 25px;
  font-family: Inter, Arial, sans-serif !important;
  font-weight: 600;
}

.country-name-a {
  text-align: center;
  color: #000000;
}

.sas-drags-panels ::placeholder {
  color: #181d27 !important;
}
.panels-btn {
  padding-top: 20px;
}
.panels-btn button {
  font-size: 14px !important;
}

.user-detail-btn button {
  font-size: 14px !important;
  font-weight: 500 !important;
}
.add-marketplace-btn {
  background-color: #155eef1a !important;
  border: none !important;
  width: 100%;
  min-width: 150px !important;
  border-radius: 8px;
  font-family: Outfit, sans-serif !important;
  text-align: center;
  padding: 15px 0px !important;
  color: #155eef;
  min-height: 44px !important;
  display: flex;
  font-size: 16px !important;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .form-btns-panels {
    right: 4px;
  }

  .sas-drags-panels .switch-panels {
    top: -2px !important;
    right: -22px !important;
  }

  .move-input-icon {
    right: 45px;
    top: 5px;
    font-size: 20px;
  }

  .custom-panels-select {
    padding: 4px 8px !important;
  }

  .form-group-panel input {
    padding: 4px 8px !important;
  }

  .form-group-panel .scan-month {
    padding: 4px 8px !important;
  }

  .dollar-input input {
    padding-left: 33px !important;
  }

  .panels-item h2 {
    font-size: 20px;
  }

  .panels-title {
    height: 38px;
  }
}

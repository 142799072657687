.sliderContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

.heading {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.description {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 2rem;
}

.slider {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  border: 1px solid red;
}

.imageContainer {
  width: 600px;
  height: 400px;
  overflow: hidden;
}

.sliderImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease;
}

.arrow {
  background: transparent;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.dots {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 1rem;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.activeDot {
  background: #bfbdbd;
  transform: scale(1.1);
}

.pricing-slider {
  background-color: blue;
}
.PricingTab_productBtn__Xi\+p2 {
  background-color: #e4e4e4 !important;
  color: black !important;
  border: none !important;
}
.silderLeft {
  margin-left: 50px;
}
.proTitle {
  font-family: "Product Sans", sans-serif;
  font-size: 60px;
  font-weight: 700;
  line-height: 46px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}
.proText {
  max-width: 500px !important;
  margin: 0 auto !important;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 24px;
  font-weight: 400;
  line-height: 33px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 20px 0px;
  color: #ffffff;
}
@media (max-width: 576px) {
  .silderLeft {
    margin-left: 0px;
  }

  .proTitle {
    font-family: "Product Sans", sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 46px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .proText {
    max-width: 500px !important;
    margin: 0 auto !important;
    font-family: Inter, Arial, sans-serif !important;
    font-size: 13px;
    font-weight: 400;
    line-height: 23px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    padding: 10px 10px;
  }
}

.instructions-item {
  padding: 10px 0;
}


.filter-box {
  border-radius: 12px;
  border: 0.5px solid #b0b0b0;
  background: rgba(255, 255, 255, 0);
  width: 46px;
  height: 46px;
  padding: 10px;
  color: #adadad;
  font-size: 24px;
}

.instructions-title-heading {
  color: #484848;
  font-family: Outfit, sans-serif !important;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.instructions-title {
  background-color: #4b1b97;
  color: white;
  border-radius: 6px 6px 0px 0px;
  height: 50px;
  flex-shrink: 0;
  padding: 20px;
  cursor: pointer;
}

.instructions-item h2 {
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.instructions-content {
  border-radius: 6px;
  background: var(--common-white-main, #fff);
  box-shadow: 0px 10px 22px 0px rgba(198, 198, 198, 0.3);
  transition: all 0.3s ease;
  flex-shrink: 0;
  padding: 20px;
  position: relative;
}

.label-item {
  padding-bottom: 4px;
  padding-top: 10px;
}

.instructions-content li {
  color: #484848;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  list-style: decimal;
}

.instructions-content p {
  color: #484848;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
}


.instructions-kit label {
  color: #484848;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
}

.instructions-kit input {
  border-radius: 12px;
  border: 0.5px solid #b0b0b0;
  opacity: 0.59;
  background: var(--common-white-main, #fff);
  width: 431px;
  height: 50px;
  flex-shrink: 0;
}

@media (max-width: 640px) {
  .instructions-kit input {
    width: 100%;
  }

  .instructions-image {
    width: 100%;
  }

  .instructions-content ol {
    padding: 10px !important;
  }
}

#chart-container .highcharts-container {
  height: 300px !important;
}

.custom-chart-gap{
  margin: 20px  0px 25px 0px;
}


@media only screen and (max-width: 600px) {
  .custom-chart-gap{
    margin: 70px  0px 25px 0px;
  }
}
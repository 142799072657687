.connect-btn {
    display: inline-flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    line-height: 16px;
    cursor: pointer;
    outline: none;
    font-size: 14px;
    font-weight: 500 !important;
}

@media (max-width: 640px) {
    .connect-btn {
        width: 100% !important;
    }
}
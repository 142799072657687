.pricingSection {
  color: black;
  padding: 40px 0;
  margin-left: 55px;
  background: #F1F4FB;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.mainHeading {
  color: #181d27;
  font-family: Inter, Arial, sans-serif !important;
  font-weight: 500;
  font-size: 32px;
  line-height: 38.73px;
  letter-spacing: 0%;
  text-align: center;
  padding-bottom: 48px;
}

.productButtons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.productBtn {
  width: 560px;
  height: 68px;
  background-color: #e4e4e4;
  border-radius: 4px;
  transition: all 0.3s ease;
  font-family: Inter, Arial, sans-serif !important;
  font-weight: 500;
  font-size: 20px;
  line-height: 24.2px;
  letter-spacing: 0%;
  border: 1px solid #e4e4e4;
}

.activeBtn {
  background-color: #155eef;
  color: white;
  width: 560px;
  height: 68px;
  border-radius: 4px;
  font-family: Inter, Arial, sans-serif !important;
  font-weight: 500;
  font-size: 20px;
  line-height: 24.2px;
  letter-spacing: 0%;
  border: 1px solid #155eef;
}

.pricingTable {
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #c6c6c6;
  box-shadow: 0px 4px 20px 0px #9d999940;
}

.tableHeader {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  background: #fff;
  border-bottom: 1px solid #c6c6c6;
}

.headerCell {
  padding: 1.5rem 1rem;
  text-align: center;
  border-right: 1px solid #c6c6c6;
  position: relative;
  color: #181d27;
  font-family: Inter, Arial, sans-serif !important;
  font-weight: 600;
  font-size: 24px;
  line-height: 29.05px;
  letter-spacing: 0%;
}

.headerCell:first-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.headerCell:last-child {
  border-right: none;
}

.headerContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  min-height: 200px;
  padding: 1rem 0;
}

.headerContent h3 {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0;
  min-height: 30px;
}

.price {
  font-family: Inter, Arial, sans-serif !important;
  font-weight: 700;
  font-size: 36.88px;
  line-height: 44.64px;
  letter-spacing: 0%;
  color: #3c3c3c;
}

.pricingRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  border-bottom: 1px solid #c6c6c6;
  color: #757575;
  font-family: Inter, Arial, sans-serif !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;
  letter-spacing: 0%;
}

.tableCell {
  padding: 1rem;
  text-align: center;
  border-right: 1px solid #c6c6c6;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableCell:first-child {
  text-align: left;
  color: #757575;
  font-family: Inter, Arial, sans-serif !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;
  letter-spacing: 0%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.tableCell:last-child {
  border-right: none;
}

.priceContainer {
  margin: 1.5rem 0;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.priceContainer .price {
  font-family: Inter, Arial, sans-serif !important;
  font-weight: 700;
  font-size: 32px;
  line-height: 38.73px;
  letter-spacing: 0%;
}

.currentPlanBtn {
  width: 100%;
  max-width: 178.5px;
  height: 48.75;
  padding: 7px 9px;
  border-radius: 4.88px;
  gap: 14.63px;
  font-family: Inter, Arial, sans-serif !important;
  font-weight: 500;
  font-size: 20px;
  line-height: 24.8px;
  color: #757575;
  background-color: #efefef;
  border: none;
  outline: none;
  margin: auto;
}

.getStartedBtn {
  width: 100%;
  max-width: 161.5px;
  height: 48.75;
  padding: 7px 9px;
  border-radius: 4.88px;
  gap: 14.63px;
  border: none;
  outline: none;
  background-color: #155eef;
  color: white;
  font-family: Inter, Arial, sans-serif !important;
  font-weight: 500;
  font-size: 20px;
  line-height: 24.8px;
  letter-spacing: 0%;
  margin: auto;
}

.getStartedBtn:hover {
  background: #0052cc;
}

.toggleContainer {
  margin-top: 1rem;
}

.toggleSwitch {
  width: 48px;
  height: 24px;
  background-color: #e0e0e0;
  border-radius: 12px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggleSwitch:hover {
  background-color: #d0d0d0;
}

.pricingGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 2rem;
}

.pricingCard {
  background: white;
  border: 1px solid #c6c6c6;
  border-radius: 12px;
  padding: 2rem;
  text-align: center;
  transition: all 0.3s ease;
}

.pricingCard:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.planTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 1rem;
}

.amount {
  font-size: 36px;
  font-weight: bold;
}

.period {
  color: #666;
}

.features {
  list-style: none;
  padding: 0;
  margin: 0 0 2rem 0;
}

.features li {
  padding: 0.5rem 0;
  color: #666;
}

.planButton {
  width: 100%;
  padding: 1rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  background: #0066ff;
  color: white;
  transition: all 0.3s ease;
}

.planButton:hover {
  background: #0052cc;
}

.currentPlan {
  background: #e0e0e0;
  cursor: default;
}

.currentPlan:hover {
  background: #e0e0e0;
}

.sliderContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

.heading {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.description {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 2rem;
}

.imageContainer {
  width: 600px;
  height: 400px;
  overflow: hidden;
}

.sliderImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease;
}

.arrow {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 1rem;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  z-index: 2;
  transition: all 0.3s ease;
}

.arrow:hover {
  background: rgba(0, 0, 0, 0.8);
}

.leftArrow {
  left: 20px;
}

.rightArrow {
  right: 20px;
}

.PricingSlider_leftArrow__6O6SG {
  left: -120px !important;
}

.PricingSlider_rightArrow__65Yri {
  right: -120px !important;
}

.dots {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 1rem;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.activeDot {
  background: #bfbdbd;
  transform: scale(1.1);
}

.popularPlan::before {
  display: none;
}

.subHeaderRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  border-bottom: 1px solid #c6c6c6;
  font-family: Inter, Arial, sans-serif !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: center;
  color: #3c3c3c;
}

.boldText {
  color: #595454;
  font-family: Inter, Arial, sans-serif !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 19.36px;
  letter-spacing: 0%;
  padding: 1rem;
    border-right: 1px solid #c6c6c6;
  text-align: left;
  height: 60px;
  justify-content: start;
  display: flex;
  align-items: center;
}

.sectionHeader {
  color: #181d27;
  font-family: Inter, Arial, sans-serif !important;
  font-weight: 600;
  font-size: 20px;
  line-height: 24.2px;
  letter-spacing: 0%;
  grid-column: 1 / -1;
  padding: 1rem;
  border-bottom: 1px solid #c6c6c6;
  text-align: left;
  background-color: #efefef;
  width: 100%;
  min-width: 800px;
}

.annualToggleContainer {
  grid-column: 1 / -1;
  padding: 2rem;
  text-align: center;
  border-top: 1px solid #eee;
  background: white;
}

.billingToggle {
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.toggleKnob {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: transform 0.3s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.toggleRight {
  transform: translateX(24px);
}

.activeText {
  color: #0066ff;
  font-weight: 500;
}

.inactiveText {
  color: #666;
}

.offerText {
  font-family: Inter, Arial, sans-serif !important;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0%;
  color: #757575;
  text-align: left;
}

.savingsText {
  color: #0066ff;
  font-family: Inter, Arial, sans-serif !important;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0%;
}

.billingPeriod {
  font-family: Inter, Arial, sans-serif !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 19.84px;
  letter-spacing: 0%;
  color: #3c3c3c;
}

.periodText {
  color: #757575;
  font-weight: 400;
  min-width: 60px;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 18px;
  line-height: 24.2px;
  letter-spacing: 0%;
}

.freePlan {
  font-size: 1.25rem;
  color: #666;
  font-weight: 500;
}

.billingTag {
  font-family: Inter, Arial, sans-serif !important;
  font-weight: 400;
  font-size: 17px;
  line-height: 20.65px;
  letter-spacing: 0%;
  color: #757575;
  min-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
}

.lookupText {
  color: #3c3c3c;
  font-family: Inter, Arial, sans-serif !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 19.84px;
  letter-spacing: 0%;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* For the subheader row */
.subHeaderRow .tableCell:first-child {
  text-align: left;
}

.proCell {
  position: relative;
  padding-top: 2rem;
}

.proBadge {
  position: absolute;
  top: 4px;
  left: 8px;
  background-color: #0066ff;
  color: white;
  font-size: 0.75rem;
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: 500;
}

.popularTag {
  position: absolute;
  top: 0px;
  left: -22px;
  z-index: 1;
  max-width: 110px;
  width: 100%;
}

.headerContenttitle {
  font-family: Inter, Arial, sans-serif !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 29.05px;
  letter-spacing: 0%;
  color: #181d27;
}
.PricingTab_activeBtn__0djSW,
.PricingTab_productBtn__Xi\+p2 {
  border: transparent !important;
}

@media screen and (max-width: 1200px) {
  .PricingSlider_leftArrow__6O6SG {
    left: -120px !important;
  }

  .PricingSlider_rightArrow__65Yri {
    right: -120px !important;
  }
}

@media screen and (max-width: 968px) {
  .PricingTab_currentPlanBtn__dMhuz,
  .PricingTab_getStartedBtn__ILYSm {
    padding: 7px 4px !important;
  }

  .currentPlanBtn {
    width: 100%;
    max-width: 128.5px;
  }

  .getStartedBtn {
    width: 100%;
    max-width: 128.5px;
  }
}

/* Add these media queries at the end of your existing CSS */

@media screen and (max-width: 768px) {
  .container {
    padding: 0 1rem;
  }

  .mainHeading {
    font-size: 24px;
    padding-bottom: 24px;
  }

  .productButtons {
    flex-direction: column;
    gap: 1rem;
  }

  .productBtn,
  .activeBtn {
    width: 100%;
    height: 50px;
    font-size: 16px;
  }

  /* Table scroll container */
  .pricingTable {
    position: relative;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin: 0 -1rem;
    padding: 0;
    min-width: calc(100% + 2rem);
  }

  /* Maintain table structure */
  .tableHeader,
  .pricingRow,
  .subHeaderRow {
    min-width: 800px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .headerCell {
    padding: 1rem;
    font-size: 18px;
  }

  .headerContent {
    min-height: 250px;
  }

  .price {
    font-size: 20px !important;
    line-height: 30px;
  }

  .lookupText {
    min-height: 30px;
    font-size: 13px !important;
  }

  .priceContainer {
    min-height: 60px;
    margin: 1rem 0;
  }

  .billingTag {
    font-size: 12px;
    min-height: 20px;
  }

  .currentPlanBtn,
  .getStartedBtn {
    padding: 8px 16px;
    font-size: 12px;
  }

  /* Custom scrollbar styling */
  .pricingTable::-webkit-scrollbar {
    height: 6px;
  }

  .pricingTable::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }

  .pricingTable::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  .pricingTable::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* First column adjustments */

  /* Toggle section adjustments */
  .billingToggle {
    flex-direction: row;
    align-items: flex-start;
  }

  .pricingSection {
    padding: 20px 20px;
    margin-left: 0px;
  }

  .PricingSlider_leftArrow__6O6SG {
    left: -40px !important;
  }

  .PricingSlider_rightArrow__65Yri {
    right: -40px !important;
  }
  .popularTag {
    position: absolute;
    top: 0px;
    left: -16px;
    z-index: 1;
    max-width: 87px;
    width: 100%;
  }
  .offerText {
    font-family: Inter, Arial, sans-serif !important;
    font-weight: 400;
    font-size: 13px;
    line-height: 26px;
    letter-spacing: 0%;
    color: #757575;
    text-align: left;
  }
  .savingsText {
    color: #0066ff;
    font-family: Inter, Arial, sans-serif !important;
    font-weight: 700;
    font-size: 13px !important;
    line-height: 26px;
    letter-spacing: 0%;
  }
  .periodText {
    color: #757575;
    font-weight: 400;
    min-width: 60px;
    font-family: Inter, Arial, sans-serif !important;
    font-size: 12px !important;
    line-height: 24.2px;
    letter-spacing: 0%;
  }
  .headerContenttitle {
    font-family: Inter, Arial, sans-serif !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 29.05px;
    letter-spacing: 0%;
    color: #181d27;
  }
  .boldText {
    color: #595454;
    font-family: Inter, Arial, sans-serif !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 19.36px;
    letter-spacing: 0%;
  }
  .tableCell:first-child {
    text-align: left;
    color: #757575;
    font-family: Inter, Arial, sans-serif !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.36px;
    letter-spacing: 0%;
    min-width: 200px;
  }
  .sectionHeader {
    color: #181d27;
    font-family: Inter, Arial, sans-serif !important;
    font-weight: 600;
    font-size: 16px;
    line-height: 24.2px;
    letter-spacing: 0%;
    grid-column: 1 / -1;
    padding: 1rem;
    border-bottom: 1px solid #c6c6c6;
    text-align: left;
    background-color: #efefef;
    width: 100%;
    min-width: 800px;
    position: sticky;
    left: 0;
  }
}

/* For very small screens */
@media screen and (max-width: 375px) {
  .mainHeading {
    font-size: 20px;
  }
  .popularTag {
    position: absolute;
    top: 0px;
    left: -16px;
    z-index: 1;
    max-width: 87px;
    width: 100%;
  }
  .productBtn,
  .activeBtn {
    font-size: 14px;
    height: 50px;
  }

  .headerCell {
    padding: 0.75rem;
  }

  .price {
    font-size: 20px;
  }
  .offerText {
    font-family: Inter, Arial, sans-serif !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0%;
    color: #757575;
    text-align: left;
  }
  .savingsText {
    color: #0066ff;
    font-family: Inter, Arial, sans-serif !important;
    font-weight: 700;
    font-size: 13px !important;
    line-height: 26px;
    letter-spacing: 0%;
  }
  .periodText {
    color: #757575;
    font-weight: 400;
    min-width: 60px;
    font-family: Inter, Arial, sans-serif !important;
    font-size: 13px !important;
    line-height: 24.2px;
    letter-spacing: 0%;
  }
  .headerContenttitle {
    font-family: Inter, Arial, sans-serif !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 29.05px;
    letter-spacing: 0%;
    color: #181d27;
  }
}

.email-bot {
  background-color: white;
  border-radius: 50%;
  padding: 6px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.email-icon {
  font-size: 2.5rem !important;
  padding: 3px;
  color: #4B1B97 !important;
}

.modal {
  --bs-modal-header-border-color: none !important;
  --bs-modal-footer-border-color: none !important;
}

.footer-email {
  justify-content: center !important;
}

.default-file-input {
  opacity: 0;
  width: 0;
}

.browse-style {
  cursor: pointer;
  display: inline-block;
  margin: 0px 10px;
  background-color: var(--basetheme) !important;
  color: white !important;
  border-radius: 1rem;
  padding: 5px 10px;
  text-align: center !important;
  text-transform: initial !important;
}

.save-button {
  cursor: pointer;
  display: inline-block;
  border: none;
  margin: 0px 10px;
  background-color: var(--basetheme) !important;
  color: white !important;
  border-radius: 0.5rem;
  padding: 5px 10px;
  text-align: center;
  text-transform: initial !important;
}

.selectfile {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.modal-header {
  justify-content: center !important;
  font-family: "albert", sans-serif;
  font-size: 1rem !important;
}

.screen-shot {
  font-family: "albert light", sans-serif;
  font-size: 1rem !important;
}

.btn-close {
  right: 13px;
  position: absolute;
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e);
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}